// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState, useEffect } from "react";
import book from '../assets/book.png';
import { Link } from "react-router-dom";
import TrackEvent from "../utils/events";
import useScrollTracker from "../utils/scrolltracking";

const Books = () => {
    useScrollTracker('BooksPage');
    const defaultSection = 'All';
    const [selectedSection, setSelectedSection] = useState(defaultSection);

    const allResources = data.flatMap((category) => category.resources); // Combine all resources

    const displayedResources = selectedSection === 'All'
        ? allResources
        : data.find((category) => category.name === selectedSection)?.resources;

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedSection(defaultSection);
    }, []);

    return (
        <div className="relative">
            <div className="flex flex-col ml-[7%] xl:ml-[12%] mr-[5%] mt-[10%] lg:mt-[5%]">
                <h1 className="font-lora font-bold text-[22px] md:text-[29px] lg:text-[36px]">Learning Resources</h1>
                {/* Display resources */}
                <div className="mt-[10%] md:mt-[5%] ml-[3%] w-[90%] items-center">
                    {displayedResources?.map((resource, index) => (
                        <Link to={`/book/${resource.id}`} key={index} state={{ resource }} onClick={() => { TrackEvent(`${resource.id}_resource_clicked`) }}>
                            <div key={index} className="flex flex-row items-center space-x-[5%] mb-4 md:mb-6 lg:md-8 w-full h-[80px] bg-[#E2E7F8] rounded-[10px]">
                                <div className="w-[1.5%] h-full bg-[#6E87DE] rounded-l-[10px]" />
                                <img
                                    src={book}
                                    alt='Image'
                                    className="h-[40%] w-auto object-contain"
                                />
                                <div className="flex flex-col space-y-2">
                                    <p className="font-roboto font-medium text-[#242528] text-[16px] md:text-[18px] lg:text-[20px]">{resource.name}</p>
                                    <p className="font-roboto font-[300] text-[#242528] text-[12px] md:text-[14px] lg:text-[16px]">{resource.date}</p>
                                </div>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>
        </div>
    );
};

export default Books;

const data = [
    {
        "categoryId": "fd",
        "name": "Fixed Deposit",
        "resources": [
            {
                "id": "fd_strategy_guide",
                "name": "FD Strategy Guide",
                "date": "12th Sept, 2024",
                "volumes": [
                    {
                        "volumeId": "v1",
                        "name": "Volume 1",
                        "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-book1.png",
                        "file": "https://finurja-e-learning.s3.ap-south-1.amazonaws.com/fd-guide.pdf"
                    },
                    {
                        "volumeId": "v2",
                        "name": "Volume 2",
                        "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-book2.png",
                        "file": ""
                    },
                ]
            },
        ]
    },
    // {
    //     "categoryId": "epf",
    //     "name": "Employee Provident Fund",
    //     "resources": [
    //         {
    //             "id": "epf_master_guide",
    //             "name": "EPF Master Guide",
    //             "date": "12th Sept, 2024",
    //             "volumes": [
    //                 {
    //                     "name": "Volume 1",
    //                     "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-Book1.png",
    //                     "file": ""
    //                 },
    //                 {
    //                     "name": "Volume 2",
    //                     "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-Book2.png",
    //                     "file": ""
    //                 },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     "categoryId": "nps",
    //     "name": "National Pension Scheme",
    //     "resources": [
    //         {
    //             "id": "nps_master_guide",
    //             "name": "NPS Master Guide",
    //             "date": "12th Sept, 2024",
    //             "volumes": [
    //                 {
    //                     "name": "Volume 1",
    //                     "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-Book1.png",
    //                     "file": ""
    //                 },
    //                 {
    //                     "name": "Volume 2",
    //                     "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/books/e-Book2.png",
    //                     "file": ""
    //                 },
    //             ]
    //         },
    //     ]
    // },
];
