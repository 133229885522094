// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import { useState, useRef, useEffect } from 'react';
import images from '../../utils/images';
import TrackEvent from '../../utils/events';

const testimonialsData = [
    {
        image: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/pareek.png",
        shaded: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/pareek-black.png",
        rating: images.rating,
        review: "Finurja has been an incredible companion in managing my finances. It has simplified the way I handle money. It offers tailored solutions that perfectly suit my needs, making it an invaluable tool for maintaining control over my finances. This app is fantastic, straightforward, and remarkably useful in keeping my financial tracking organized."
    },
    {
        image: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/bisht.png",
        shaded: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/bisht-black.png",
        rating: images.rating,
        review: "I tried the corporate fixed deposit feature, and it's genuinely a good move for better returns. The app is quite simple, which is good, but I wish they could add more tips or suggestions for someone who isn't very familiar with financial terms. Still, it's been quite handy in keeping things in check."
    },
    {
        image: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/dixit.png",
        shaded: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/dixit-black.png",
        rating: images.rating,
        review: "finurja is a good sidekick for my finances. The corporate fixed deposit is great. It's good and simple, but maybe add more insights on investments."
    },
    {
        image: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/sharma.png",
        shaded: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/sharma-black.png",
        rating: images.rating,
        review: "All right, finurja – my partner in financial stuff. Money, fixed deposits, and corporate fixed deposit stuff – it’s smooth. The app is simple, maybe toss in some extras for goal-based savings? It's decent, and does what it says for my financial planning."
    },
];

const TestimonialsSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const scrollRef = useRef(null);
    const visibleTestimonials = 3;

    // For desktop scrolling (left)
    const scrollLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length);
    };

    // For desktop scrolling (right)
    const scrollRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    };

    // Get the visible testimonials based on the current index (Desktop)
    const getVisibleTestimonials = () => {
        return testimonialsData
            .slice(currentIndex, currentIndex + visibleTestimonials)
            .concat(
                testimonialsData.slice(0, Math.max(0, (currentIndex + visibleTestimonials) - testimonialsData.length))
            );
    };

    // For mobile scrolling
    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            const handleScroll = () => {
                const scrollPosition = scrollContainer.scrollLeft;
                const itemWidth = scrollContainer.offsetWidth * 0.6;
                const newIndex = Math.round(scrollPosition / itemWidth) % testimonialsData.length;
                setCurrentIndex(newIndex);
            };

            scrollContainer.addEventListener('scroll', handleScroll);
            return () => scrollContainer.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.scrollLeft = testimonialsData.length * scrollContainer.offsetWidth * 0.6;
        }
    }, []);

    const handleTestimonialClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
        TrackEvent('testimonial_clicked');
    };

    return (
        <>
            {/* Common Section Header */}
            <div className='flex justify-center mt-[5%]'>
                <h1 className='font-roboto font-bold text-[15px] md:text-[26px] lg:text-[38px] xl:text-[48px]'>
                    Testimonials
                </h1>
            </div>

            {/* Desktop Version */}
            <div className='hidden xl:flex mt-[2%]'>
                <div className='w-full h-auto bg-repeat bg-center bg-cover' style={{ backgroundImage: `url(${images.testinomialsBg})` }}>
                    <div className='flex flex-row items-center justify-center h-full mx-[1%]'>
                        <img
                            className="w-[29px] h-auto mr-[2%] cursor-pointer"
                            src={images.leftArrow}
                            alt="Left Arrow"
                            onClick={scrollLeft}
                        />
                        <div className='flex items-center justify-between h-full w-[90%] my-[3%]'>
                            {getVisibleTestimonials().map((testimonial, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col items-center w-[25%] h-auto bg-white rounded-[20px] p-[1%] cursor-pointer transition-all duration-300 ${expandedIndex === index ? 'h-[95%] p-0' : 'h-[75%]'}`}
                                    onClick={() => handleTestimonialClick(index)}
                                >
                                    <img
                                        className={`w-full h-auto  ${expandedIndex === index ? 'rounded-tl-[20px] rounded-tr-[20px]' : 'rounded-[20px]'}`}
                                        src={expandedIndex === index ? testimonial.image : testimonial.shaded}
                                        alt="Testimonial Image"
                                    />
                                    <img
                                        className="w-[50%] h-auto mt-[5%]"
                                        src={testimonial.rating}
                                        alt="Rating"
                                    />
                                    <p className={`font-roboto text-[25px] text-[#3C3E43] mt-[5%] px-[5%] py-[1%] ${expandedIndex === index ? 'line-clamp-none' : 'text-[25px] line-clamp-3'}`}>
                                        {testimonial.review}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <img
                            className="w-[29px] h-auto ml-[2%] cursor-pointer"
                            src={images.rightArrow}
                            alt="Right Arrow"
                            onClick={scrollRight}
                        />
                    </div>
                </div>
            </div>

            {/* Mobile Version */}
            <div className='xl:hidden mt-[4%]'>
                <div className='w-full h-auto bg-repeat bg-center bg-cover' style={{ backgroundImage: `url(${images.testinomialsBg})` }}>
                    <div
                        ref={scrollRef}
                        className='flex overflow-x-scroll snap-x snap-mandatory h-full mx-[1%] scrollbar-none'
                    >
                        {testimonialsData.map((testimonial, index) => (
                            <div
                                key={index}
                                className={`flex-shrink-0 items-center w-[50%] h-auto snap-center my-[10%] mx-[5%] bg-white rounded-[15px] px-[2%] pt-[2%] pb-[5%] shadow-md transition-transform transform duration-300 ${index === expandedIndex ? 'scale-100' : 'scale-90'}`}
                                onClick={() => handleTestimonialClick(index)}
                            >
                                <img
                                    className="w-full h-auto rounded-[15px]"
                                    src={expandedIndex === index ? testimonial.image : testimonial.shaded}
                                    alt="Testimonial Image"
                                />
                                <div className="flex justify-center items-center w-full mt-[5%]">
                                    <img
                                        className="w-[50%] h-auto"
                                        src={testimonial.rating}
                                        alt="Rating"
                                    />
                                </div>
                                <p className={`line-clamp-3 font-roboto text-[13px] md:text-[24px] lg:text-[35px] text-[#3C3E43] mt-[5%] px-[5%] ${index === expandedIndex ? 'line-clamp-none' : ''}`}>
                                    {testimonial.review}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TestimonialsSection;
