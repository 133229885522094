// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Home from '../pages/Home';
import About from '../pages/About';
import Offering from '../pages/Offering';
import Blogs from '../pages/Blogs';
import Blog from '../pages/Blog';
import ContactUs from '../pages/ContactUs';
import Books from '../pages/Books';
import BookDetails from '../pages/BookDetails';
import Privacy from '../pages/Privacy';
import EULA from '../pages/eula';

const AppRoutes = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/offering/:category" element={<Offering />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="blog/:id" element={<Blog />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path='/books' element={<Books />} />
                    <Route path='/book/:id' element={<BookDetails />} />
                    <Route path='/privacy-policy' element={<Privacy />} />
                    <Route path='/eula' element={<EULA />} />
                </Routes>
            </Layout>
        </Router>
    );
};

export default AppRoutes;