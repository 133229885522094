// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import AppRoutes from '../src/routes/routes';

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
