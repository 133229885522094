import { useState, useEffect } from 'react';
import TrackEvent from './events';

function useScrollTracker(pageName) {
    const [scrollCount, setScrollCount] = useState(0);
    const [startTime] = useState(Date.now());
    const [timeSpent, setTimeSpent] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollCount(prevCount => prevCount + 1); // Increment scroll count
        };

        const handleUnload = () => {
            const endTime = Date.now();
            const totalTimeSpent = Math.floor((endTime - startTime) / 1000); // Convert to seconds
            setTimeSpent(totalTimeSpent);

            // Track the total scroll count and time spent when user leaves the page
            TrackEvent("website_page_time_spent", { pageName, timeSpent: totalTimeSpent, scrollCount });
        };

        // Set up event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('beforeunload', handleUnload);

        // Clean up on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [startTime, pageName, scrollCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeSpent(Math.floor((Date.now() - startTime) / 1000)); // Update time spent in seconds
        }, 1000);

        return () => clearInterval(interval);
    }, [startTime]);

    return { scrollCount, timeSpent };
}

export default useScrollTracker;
