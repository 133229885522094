// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.


import images from "../../utils/images";
import { Link } from "react-router-dom";
import TrackEvent from "../../utils/events";

const Blogs = () => {
    return (
        <div className='flex flex-col w-[100%] items-center xl:mt-[5%] mt-[10%]'>
            <h className='font-roboto font-semibold text-[#3C3E43] text-[15px] md:text-[26px] lg:text-[35px] xl:text-[48px]'>Finurja Micro Blogs</h>
            <div className='flex flex-col items-center xl:py-[1%] py-[2%] mt-[2%] xl:w-[80%] w-[90%] h-full fill-white bg-white shadow-[0px_4px_51.1px_rgba(64,_123,_255,_0.2)] rounded-[20px]'>
                <img
                    className="xl:w-[50%] w-[96%] h-auto rounded-[10px]"
                    src={images.blogImage}
                    alt="Testimonial Image"
                />
                <h1 className='xl:mt-[1%] xl:w-[50%] mt-[3%] w-[60%] font-roboto font-semibold text-[12px] md:text-[21px] lg:text-[30px] xl:text-[30px]  text-[#3C3E43] text-center'>4 Key Money Lessons you can Learn from Dunki Movie</h1>
                <p className="xl:line-clamp-4 line-clamp-3 xl:w-[50%] w-[90%] font-roboto text-[12px] md:text-[21px] lg:text-[30px] xl:text-[30px] text-[#3C3E43] mt-[2%] text-center">
                    Dunki, Shah Rukh Khan's Bollywood film, offers a distinct difference between the charming depiction of Punjab in mainstream society and the cruel real factors faced by its occupants. The film follows the tale of three companions Santosh, Ponky, and Malik, who are tricked by the commitment to a superior life in Britain by corrupt visa specialists. They share their life reserve funds with these specialists, just to be misled.
                </p>
                <Link
                    to={"/blogs"}
                    onClick={() => { TrackEvent('read_more_blogs_clicked') }}
                >
                    <div className="inline-flex items-center justify-center space-x-2 bg-[#2B4CBA] rounded-[44px] py-3 xl:mt-[3%] mt-[5%] px-6">
                        <h2 className='font-roboto font-bold text-[14px] md:text-[21px] lg:text-[28px] xl:text-[28px]  text-[#ffffff]'>Read more blogs</h2>
                        <img
                            className="h-[12px] md:h-[18px] lg:h-[24px] xl:h-[24px] w-auto"
                            src={images.featureArrow}
                            alt="Feature Arrow"
                        />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Blogs;