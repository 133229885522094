// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';
import images from "../../utils/images";
import { Link } from 'react-router-dom';
import TrackEvent from '../../utils/events';

const offerings = [
    {
        id: 'money_tracking',
        type: 'left',
        h1: 'Money Tracking',
        p: 'Keep an eye on all your finances in one place by tracking your bank accounts, NPS, EPF, Mutual Fund, and FD/RD seamlessly. Get real-time insights and manage your investments effortlessly!',
        image: images.moneyTracking,
        isComingSoon: false
    },
    {
        id: 'higher_returns',
        type: 'right',
        h1: 'Higher Returns',
        p: 'Open a high-interest Fixed Deposit (FD) and maximize your savings with tailored recommendations just for you. Secure your future with smart investment choices!',
        image: images.higherReturns,
        isComingSoon: false
    },
    {
        id: 'more_savings',
        type: 'left',
        h1: 'More Savings',
        p: 'Track your savings, income, NPS, EPF, and mutual fund trends easily with real-time updates to stay on top of your financial health. Get a clear view of your investment performance and make informed decisions effortlessly.',
        image: images.moreSavings,
        isComingSoon: false
    },
    {
        id: 'avoid_losses',
        type: 'right',
        h1: 'Avoid Losses',
        p: 'Receive instant alerts for any unusual or suspicious activity in your bank and investment accounts, ensuring your financial security at all times. Stay informed and take quick action to protect your money.',
        image: images.avoidLosses,
        isComingSoon: false
    },
    {
        id: 'better_financially_prepared',
        type: 'left',
        h1: 'Better Financially Prepared',
        p: 'Keep track of all your financial goals, from retirement and college funds to wedding expenses, home buying, loan prepayments, emergency savings, and parental care, all in one place.',
        image: images.betterFinanciallyPrepared,
        isComingSoon: false
    },
    {
        id: 'seek_financial_advise',
        type: 'right',
        h1: 'Seek Financial Advise',
        p: 'Trusted circle of your closest confidants, where you can seek personalized advice or assistance, share information, and receive guidance, all while maintaining predefined privacy settings.',
        image: images.seekFinancialAdvise,
        isComingSoon: true
    },
];

const Offerings = ({ offeringsRef }) => {
    return (
        <div ref={offeringsRef} className="offerings-section">
            <div className='hidden xl:flex flex-col mt-12'>
                <div className='flex justify-center mb-[5%]'>
                    <h1 className='font-roboto font-bold text-[48px]'>
                        Our Offerings
                    </h1>
                </div>
                {offerings.map((item, index) => (
                    <div key={index} className='flex mx-[10%] bg-[#E2E7F8] rounded-[20px] p-[32px] mb-[8%]'>
                        {item.type === 'left' ? (
                            <>
                                <div className="flex-1 flex flex-col mr-[32px]">
                                    <h1 className='font-roboto font-bold text-[36px] text-[#3C3E43]'>{item.h1}</h1>
                                    <p className='mt-[24px] mr-[64px] font-roboto font-medium text-[24px] text-[#6D6F78]'>
                                        {item.p}
                                    </p>
                                    <Link
                                        to={`/offering/${item.id}`}
                                        onClick={() => {
                                            TrackEvent(`${item.id}_offering_clicked`);
                                        }}
                                    >
                                        <div className="inline-flex items-center space-x-2 bg-[#2B4CBA] rounded-[44px] px-[44px] py-[14px] mt-[48px] w-auto">
                                            <h2 className='font-roboto font-bold text-[14px] md:text-[21px] lg:text-[28px] text-[#ffffff] whitespace-nowrap'>Learn more</h2>
                                            <img
                                                className="w-[22px] h-auto"
                                                src={images.featureArrow}
                                                alt="Feature Arrow"
                                            />
                                        </div>
                                    </Link>
                                </div>
                                <div className="flex-1 flex items-center justify-center">
                                    <img
                                        className="w-auto h-auto max-w-full max-h-full object-cover rounded-[10px]"
                                        src={item.image}
                                        alt="Descriptive alt text"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex-1 flex items-center justify-center">
                                    <img
                                        className="h-auto max-w-full rounded-[10px]"
                                        src={item.image}
                                        alt="Descriptive alt text"
                                    />
                                </div>
                                <div className="flex-1 flex flex-col ml-[32px]">
                                    <h1 className='font-roboto font-bold text-[36px] text-[#3C3E43]'>{item.h1}</h1>
                                    <p className='mt-[24px] mr-[64px] font-roboto font-medium text-[24px] text-[#6D6F78]'>
                                        {item.p}
                                    </p>
                                    <Link
                                        to={item.isComingSoon ? "" : `/offering/${item.id}`}
                                        onClick={() => {
                                            TrackEvent(`${item.id}_offering_clicked`);
                                        }}
                                    >
                                        <div className="inline-flex items-center space-x-2 bg-[#2B4CBA] rounded-[44px] px-[44px] py-[14px] mt-[48px] w-auto">
                                            <h2 className='font-roboto font-bold text-[14px] md:text-[21px] lg:text-[28px] text-[#ffffff]'>
                                                {item.isComingSoon ? 'Coming Soon' : 'Learn more'}
                                            </h2>
                                            {!item.isComingSoon && (
                                                <img
                                                    className="w-[22px] h-auto"
                                                    src={images.featureArrow}
                                                    alt="Feature Arrow"
                                                />
                                            )}

                                        </div>
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <div className='xl:hidden mt-12'>
                <div className='flex justify-center mb-[5%]'>
                    <h1 className='font-roboto font-bold text-[20px] md:text-[30px] lg:text-[48px]'>
                        Our Offerings
                    </h1>
                </div>
                {offerings.map((item, index) => (
                    <>
                        <div className='flex justify-center'>
                            <h1 className='font-roboto font-bold text-[15px] md:text-[26px] lg:text-[38px] text-[#3C3E43]'>{item.h1}</h1>
                        </div>
                        <div key={index} className='flex flex-col mx-[14px] bg-[#E2E7F8] rounded-[20px] p-[10px] mt-[3%] mb-[10%]'>
                            <div className="flex flex-col items-center justify-center flex-grow">
                                {/* Image */}
                                <img
                                    className="h-auto max-w-full rounded-[10px]"
                                    src={item.image}
                                    alt="Descriptive alt text"
                                />

                                {/* Button */}
                                <Link
                                    to={item.isComingSoon ? "" : `/offering/${item.id}`}
                                    onClick={() => {
                                        TrackEvent(`${item.id}_offering_clicked`);
                                    }}
                                >
                                    <div className="inline-flex items-center justify-center space-x-2 bg-[#2B4CBA] rounded-[44px] px-[24px] py-[8px] mt-[20px] mb-[6px]">
                                        <h2 className='font-roboto font-bold text-[14px] md:text-[21px] lg:text-[28px] text-[#ffffff]'>
                                            {item.isComingSoon ? 'Coming Soon' : 'Learn more'}
                                        </h2>
                                        {!item.isComingSoon && (
                                            <img
                                                className="w-[12px] md:w-[19px] lg:w-[26px] h-auto"
                                                src={images.featureArrow}
                                                alt="Feature Arrow"
                                            />
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>

                ))}
            </div>
        </div>
    )
}

export default Offerings;