// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';

const images = [
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/amfi.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/bajaj.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/mahindra.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/onemoney.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/sahamati.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/aws.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/trust/ssl.png",
];

const Trust = () => {
    return (
        <>
            <div className='hidden lg:flex flex-col'>
                <div className='flex flex-col justify-center w-full h-[5%] bg-[#213A8F] items-center mt-[10%] py-[1%]'>
                    <h className='font-roboto font-semibold text-[#ffffff] text-[24px]'>
                        Institutional grade security with partners you can trust
                    </h>
                </div>
                <div className='w-full flex mt-[2%]'>
                    <div className='flex w-full items-center justify-center'>
                        {[...images].map((image, index) => (
                            <div key={index} className='w-[8%] mx-[2%]'>
                                <img
                                    src={image}
                                    alt={`Partner ${index + 1}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex lg:hidden flex-col'>
                <div className='flex flex-col justify-center bg-[#213A8F] items-center mt-[10%] py-[3%]'>
                    <h className='font-roboto font-semibold text-[#ffffff] text-[10px]'>
                        Institutional grade security with partners you can trust
                    </h>
                </div>
                <div className='flex w-full my-[5%] overflow-x-scroll'>
                    <div className='flex w-full animate-scroll items-center justify-start flex-nowrap'>
                        {[...images].map((image, index) => (
                            <div key={index} className='w-[20%] flex-shrink-0 mx-[5%]'>
                                <img
                                    src={image}
                                    alt={`Partner ${index + 1}`}
                                    className='w-full h-auto'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trust;