// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import images from "../../utils/images";
import TrackEvent from "../../utils/events";

const Footer = () => {
    return (
        <div className="flex flex-col items-center mt-[10%]">
            <img
                className="lg:w-[265px] w-[30%] h-auto"
                src={images.logoUrl}
                alt="Finurja logo"
            />
            <div className="flex flex-col w-full bg-gradient-to-b from-[#213A8F] to-[#182A68] lg:mt-[2%] mt-[5%] lg:py-[3%] pt-[10%]">
                {/* Top Section - Links */}
                <div className="flex flex-col lg:flex-row justify-around w-full mb-8 lg:space-y-0 space-y-8">
                    {/* About Us Section */}
                    <div className="flex flex-col text-white text-center lg:text-left">
                        <h3 className="font-roboto text-[14px] md:text-[19px] lg:text-[24px] font-bold lg:mb-8 mb-4">About us</h3>
                        <a href="/about" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] lg:mb-2 mb-1.5" onClick={() => { TrackEvent('footer_about_us_clicked') }}>Finurja</a>
                        <a href='https://www.instagram.com/financially_aware_bharat/' target="_blank" rel="noopener noreferrer" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] lg:mb-2 mb-1.5" onClick={() => { TrackEvent('footer_community_clicked') }}>Community</a>
                        <a href="/contact" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px]" onClick={() => { TrackEvent('footer_contact_us_clicked') }}>Contact Us</a>
                    </div>

                    {/* Resources Section */}
                    <div className="flex flex-col text-white text-center lg:text-left">
                        <h3 className="font-bold font-roboto text-[14px] md:text-[19px] lg:text-[24px] lg:mb-8 mb-4">Resources</h3>
                        <a href="/blogs" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] mb-2" onClick={() => { TrackEvent('footer_blogs_clicked') }}>Blogs</a>
                        <a href="/books" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px]" onClick={() => { TrackEvent('footer_learning_resources_clicked') }}>Learning Resources</a>
                    </div>

                    {/* Legal Section */}
                    <div className="flex flex-col text-white text-center lg:text-left">
                        <h3 className="font-bold font-roboto text-[14px] md:text-[19px] lg:text-[24px] lg:mb-8 mb-4">Legal</h3>
                        <a href="/eula" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] mb-2" onClick={() => { TrackEvent('footer_eula_clicked') }}>EULA</a>
                        <a href="/privacy-policy" className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px]" onClick={() => { TrackEvent('footer_privacy_policy_clicked') }}>Privacy Policies</a>
                    </div>
                </div>

                {/* Divider */}
                <hr className="w-full border-t border-white opacity-30 lg:mb-8 mb-6" />

                {/* Middle Section - QR Code */}
                <div className="hidden lg:flex justify-center mb-8">
                    <div className="flex flex-col items-center">
                        <div className="bg-white rounded-lg p-1 flex justify-center items-center">
                            <img src={images.qr} alt="QR Code" className="w-32 h-32 rounded-md" />
                        </div>
                        <p className="text-white font-roboto font-medium text-[20px] mt-3">Scan & download finurja</p>
                    </div>
                </div>

                <div className="flex lg:hidden justify-center mb-2">
                    <div className="inline-flex items-center mt-1">
                        <a href="https://play.google.com/store/apps/details?id=com.finurja.finurja_mobile&hl=en_IN" target="_blank" rel="noopener noreferrer" onClick={() => { TrackEvent('footer_download_app_clicked') }}>
                            <div className="bg-[#182A68] border-[0.25px] border-[#FFFFFF] rounded-[4.4px] flex items-center justify-center py-1 px-4">
                                <span className="text-[#FFFFFF] text-[12px] md:text-[16px] font-medium">
                                    Download App
                                </span>
                                <img
                                    className="w-[15px] h-[15px] md:w-[20px] md:h-[20px] ml-[8px]"
                                    src={images.googlePlayIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex lg:hidden justify-center mb-8">
                    <div className="inline-flex items-center mt-1">
                        <a href="https://apps.apple.com/in/app/finurja-banks-fds-nps-epf-mf/id6736566130" target="_blank" rel="noopener noreferrer" onClick={() => { TrackEvent('footer_download_app_clicked') }}>
                            <div className="bg-[#182A68] border-[0.25px] border-[#FFFFFF] rounded-[4.4px] flex items-center justify-center py-1 px-4">
                                <span className="text-[#FFFFFF] text-[12px] md:text-[16px] font-medium">
                                    Download App
                                </span>
                                <img
                                    className="h-[15px] md:h-[20px] w-auto  ml-[8px]"
                                    src={images.appStoreIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>
                    </div>
                </div>

                {/* Bottom Section - Social Icons */}
                <div className="flex justify-center lg:mb-4 lg:mt-10">
                    <p className="text-white font-roboto font-bold text-[12px] md:text-[19px] lg:text-[24px]  text-center mb-4">Follow us</p>
                </div>
                <div className="flex justify-center space-x-6 mb-4">
                    <a href="https://www.instagram.com/financially_aware_bharat/" target="_blank" rel="noopener noreferrer"><img src={images.instagram} alt="Instagram" className="lg:w-6 lg:h-6 w-3.5 h-3.5 md:w-5 md:h-5" onClick={() => { TrackEvent('footer_instagram_clicked') }} /></a>
                    <a href="https://www.facebook.com/finurja" target="_blank" rel="noopener noreferrer"><img src={images.meta} alt="Meta" className="lg:w-6 lg:h-6 w-3.5 h-3.5 md:w-5 md:h-5" onClick={() => { TrackEvent('footer_facebook_clicked') }} /></a>
                    <a href="https://mobile.twitter.com/finurjaofficial" target="_blank" rel="noopener noreferrer"><img src={images.twiter} alt="Twitter" className="lg:w-6 lg:h-6 w-3.5 h-3.5 md:w-5 md:h-5" onClick={() => { TrackEvent('footer_twitter_clicked') }} /></a>
                    <a href="https://wa.me/919324099395?text=Hi!" target="_blank" rel="noopener noreferrer"><img src={images.whatsapp} alt="WhatsApp" className="lg:w-6 lg:h-6 w-3.5 h-3.5 md:w-5 md:h-5" onClick={() => { TrackEvent('footer_whatsapp_clicked') }} /></a>
                </div>
            </div>
            <div className="flex flex-row justify-between lg:px-[12%] px-[6%] py-3 bg-[#F9FAFE] w-full">
                <p className="font-roboto font-medium lg:text-[18px] md:text-[14px] text-[10px]">Made in India</p>
                <p className="font-roboto font-medium lg:text-[18px] md:text-[14px] text-[10px]">Copyrights Artha Astra Inc. 2023</p>
            </div>
        </div>
    );
};

export default Footer;