// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Layout = ({ children }) => {
    return (
        <>
            <Navbar />
            <main className='pt-[50px] lg:pt-[100px]'>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
