// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

// Declare fbq as a global variable
/* global fbq, gtag */

import mixpanel from 'mixpanel-browser';

mixpanel.init('fb5ae8b959cbc2655d43023f4daeb0f2', {
    track_pageview: "full-url",
});

const TrackEvent = (eventName, properties = {}) => {
    fbq('track', eventName, properties);
    gtag('event', eventName, properties);
    mixpanel.track(eventName, properties)
};

export default TrackEvent;