// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useEffect, useState } from 'react';
import { fetchBlogs } from '../service/api';
import heart from '../assets/heart.png';
import TrackEvent from '../utils/events';
import useScrollTracker from "../utils/scrolltracking";

const Blogs = () => {
    useScrollTracker('BlogsPage');

    const [blogs, setBlogs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const loadInitialBlogs = async () => {
            setLoading(true);
            try {
                const initialBlogs = await fetchBlogs(12, 0);
                setBlogs(initialBlogs);
                setOffset(12);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
            setLoading(false);
            TrackEvent('initial_blogs_loaded');
        };

        loadInitialBlogs();
    }, []);

    const loadMoreBlogs = async () => {
        setLoading(true);
        try {
            const newBlogs = await fetchBlogs(6, offset);
            setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
            setOffset((prevOffset) => prevOffset + 6);
        } catch (error) {
            console.error('Error fetching more blogs:', error);
        }
        setLoading(false);
        TrackEvent('load_more_blogs_clicked');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date);
    };


    return (
        <div className="flex flex-col mx-[5%] xl:mx-[12%] mt-[10%] md:mt-[5%] lg:mt-[3%]">
            <h1 className="font-lora font-bold text-[22px] md:text-[29px] lg:text-[36px]">Micro Blogs</h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-[5%]">
                {blogs.map((blog) => (
                    <a href={blog.sourceLink} target="_blank" rel="noopener noreferrer" onClick={() => TrackEvent('blog_clicked')}>
                        <div className="bg-white rounded-[12px] shadow-blog p-3 flex flex-col h-full">
                            <p className="font-roboto font-normal text-[14px] text-[#3C3E43] mb-2">{formatDate(blog.createdAt)}</p>

                            {/* Set a fixed height for the image */}
                            <img
                                className="w-full h-auto object-cover mb-3 flex-grow"
                                src={blog.imageURL}
                                alt={blog.title}
                            />

                            {/* Use flex-grow for the title */}
                            <h1 className="font-roboto font-medium text-[20px] text-[#3C3E43] mb-3">{blog.title}</h1>

                            {/* Adjust the body text */}
                            <p className="font-roboto font-normal text-[14px] md:text-[15px] lg:text-[16px] mb-3">{blog.body}</p>

                            <div className="flex flex-row justify-between w-full bg-[#FAFAFA] border-[#E4E5E7] border-[1.15px] rounded-[12px] p-4 mt-[3%]">
                                <p className="font-roboto font-normal text-[14px] text-[#3C3E43]">{blog.views} views</p>
                                <div className="flex flex-row space-x-1 items-center">
                                    <p className="font-roboto font-normal text-[14px] text-[#3C3E43]">{blog.likes}</p>
                                    <img
                                        className="w-[23px] h-auto"
                                        src={heart}
                                        alt="heart icon"
                                    />
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>


            {/* Conditionally show Load More button */}
            {blogs.length > 0 && !loading && (
                <p
                    className='w-full text-center font-roboto text-[#213A8F] text-[24px] underline cursor-pointer mt-4'
                    onClick={loadMoreBlogs}
                >
                    Load More
                </p>
            )}

            {loading && <p className="text-center mt-4">Loading...</p>}

        </div>
    );
};

export default Blogs;
