// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState, useEffect } from 'react';
import images from "../../utils/images";
import TrackEvent from '../../utils/events';
import rightArrow from '../../assets/banners_right_arrow.png';
import leftArrow from '../../assets/banners_left_arrow.png';

const carouselData = [
    {
        id: 1,
        imgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/dbs_web_new.png",
        mobileImgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/dbs_mobile.png",
        link: "https://www.dbs.com/in/getaccount/?utm_source=Finurja",
        altText: "Slide 1",
        event: "website_dbs_banner_clicked"
    },
    {
        id: 3,
        imgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/private_fd_web_new.png",
        mobileImgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/book_fd_mobile.png",
        link: 'https://finurjamobile.page.link/download',
        altText: "Slide 2",
        event: "website_private_fd_banner_clicked"
    },
    {
        id: 2,
        imgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/refer_earn_web_new.png",
        mobileImgSrc: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/banners/refer_earn_mobile.png",
        link: "https://finurjamobile.page.link/download",
        altText: "Slide 1",
        event: "website_refer_earn_banner_clicked"
    },
];

const Introduction = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(null);

    // Handle auto-slide every 5 seconds
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) =>
    //             prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
    //         );
    //     }, 5000);

    //     return () => clearInterval(interval); // Cleanup interval on unmount
    // }, []);

    // Handle swipe gesture
    const handleTouchStart = (e) => setTouchStart(e.touches[0].clientX);

    const handleTouchEnd = (e) => {
        if (!touchStart) return;

        const touchEnd = e.changedTouches[0].clientX;
        const swipeDistance = touchStart - touchEnd;

        if (swipeDistance > 50) {
            handleNextClick();
        } else if (swipeDistance < -50) {
            handlePrevClick();
        }

        setTouchStart(null); // Reset touch start position
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <>
            {/* Web Version */}
            <div className="hidden xl:flex mx-[10%] mt-[2%] mb-[8%] pt-[3%] bg-[#F9FAFE] rounded-[20px] border-[1.875px] border-[#F4F4F4] shadow-[0_0_15.75px_rgba(64,123,255,0.07)]">
                {/* Left Section: Carousel */}
                <div className="flex-1 flex flex-col py-[2%] pl-[5%] justify-between relative">
                    <h1 className="font-lora font-bold text-[34px] text-[#0E193E]">
                        Tools and co-pilot for preserving
                        <br />
                        <span className="text-[#0E193E]">and growing your </span>
                        <span className="text-[#4565D4] italic">money!</span>
                    </h1>

                    {/* Carousel Wrapper */}
                    <div className="relative pb-[2%] px-[10%]">
                        {/* Left Arrow */}
                        <img
                            className="w-[14px] h-auto absolute top-[45%] left-[2%] transform -translate-y-1/2 cursor-pointer"
                            src={leftArrow}
                            alt="Left Arrow"
                            onClick={handlePrevClick}
                        />

                        {/* Right Arrow */}
                        <img
                            className="w-[14px] h-auto absolute top-[45%] right-[2%] transform -translate-y-1/2 cursor-pointer"
                            src={rightArrow}
                            alt="Right Arrow"
                            onClick={handleNextClick}
                        />

                        {/* Carousel Container */}
                        <div className="overflow-hidden">
                            <div
                                className="flex transition-transform duration-500"
                                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                            >
                                {carouselData.map((slide, index) => (
                                    <a
                                        key={index}
                                        href={slide.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex-shrink-0 w-full"
                                        onClick={() => TrackEvent(slide.event)}
                                    >
                                        <img
                                            src={slide.imgSrc}
                                            alt={slide.altText}
                                            className="w-full h-auto object-cover"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>

                        {/* Indicators */}
                        <div className="flex space-x-2 justify-center mt-4">
                            {carouselData.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentIndex(index)}
                                    className={`${index === currentIndex ? "w-7" : "w-2"} h-2 rounded-full ${index === currentIndex ? "bg-[#213A8F]" : "bg-[#97AAE7]"}`}
                                ></button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right Section: Static Image */}
                <div className="flex-1">
                    <img
                        className="object-contain w-full"
                        src={images.introAssetsWeb}
                        alt="Descriptive alt text"
                    />
                </div>
            </div>


            {/* Mobile Version */}
            <div
                className="xl:hidden w-full mt-[10%] justify-center relative"
            >
                {/* Gradient Section */}
                <div
                    className="absolute top-0 w-full h-[92%] z-[-1]" // Adjust height as needed
                    style={{
                        background:
                            "linear-gradient(152.37deg, rgba(0, 58, 255, 0) 17.14%, rgba(57, 101, 247, 0) 52.11%, rgba(172, 191, 255, 0.48) 82.77%)",
                    }}
                ></div>

                {/* Text Section */}
                <div className="px-[5%] text-center">
                    <h1 className="font-lora font-bold text-[18px] md:text-[30px] lg:text-[40px] text-[#0E193E]">
                        Tools and co-pilot for preserving
                        <br />
                        <span className="text-[#0E193E]"> and growing your </span>
                        <span className="text-[#4565D4] italic">money!</span>
                    </h1>
                </div>

                <div className="relative w-full overflow-hidden mt-9">
                    {/* Carousel Slides */}
                    <div
                        className="flex transition-transform duration-500"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        {carouselData.map((slide) => (
                            <div
                                key={slide.id}
                                className="flex-shrink-0 w-full h-auto"
                            >
                                <a href={slide.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={slide.mobileImgSrc}
                                        alt={slide.altText}
                                        className="w-full h-full object-cover cursor-pointer"
                                    />
                                </a>

                            </div>
                        ))}
                    </div>

                    {/* Left Arrow */}
                    {/* <img
                        className="w-[12px] h-auto absolute top-1/2 left-[2%] transform -translate-y-1/2 cursor-pointer"
                        src={leftArrow}
                        alt="Left Arrow"
                        onClick={handlePrevClick}
                    /> */}

                    {/* Right Arrow */}
                    {/* <img
                        className="w-[12px] h-auto absolute top-1/2 right-[2%] transform -translate-y-1/2 cursor-pointer"
                        src={rightArrow}
                        alt="Right Arrow"
                        onClick={handleNextClick}
                    /> */}

                    {/* Indicators */}
                    <div className="flex space-x-2 justify-center mt-3">
                        {carouselData.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                className={`${index === currentIndex ? "w-6" : "w-2"}  h-2 rounded-full ${index === currentIndex ? "bg-[#55575D]" : "bg-[#A2A4AA]"}`}
                            ></button>
                        ))}
                    </div>
                </div>

                <div
                    className="flex justify-center w-full"

                >
                    <img
                        className="object-contain w-[90%] mt-12 items-center"
                        src={images.introAssets}
                        alt="Descriptive alt text"
                    />
                </div>


            </div>
        </>
    );
}

export default Introduction;
