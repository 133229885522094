// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState, useEffect } from 'react';
import images from "../../utils/images";
import { Link } from 'react-router-dom';
import TrackEvent from '../../utils/events';

const imagesList = [
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/learning.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/together.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/things.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/impact.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/money.png",
    "https://finurja-website-images.s3.ap-south-1.amazonaws.com/community/mindful.png"
];

const Community = () => {
    return (
        <div className="community-section">
            <div className='hidden xl:flex flex-col'>
                <div className='flex justify-center mt-[10%]'>
                    <h1 className='font-roboto font-bold text-[48px]'>
                        Financially Aware Bharat
                    </h1>
                </div>

                <div className='flex mx-[10%] bg-[#E2E7F8] rounded-[20px] p-[32px] mt-[3%] mb-[2%]'>
                    <div className="flex-1 flex items-center justify-center">
                        <ImageCarousel images={imagesList} altText="Descriptive alt text" timeInterval={1000} />
                    </div>
                    <div className="flex-1 flex flex-col ml-[10%]">
                        <h1 className='font-roboto font-bold text-[36px] text-[#3C3E43]'>We're deeply committed to improve money mindfulness</h1>
                        <p className='mt-[24px] mr-[64px] font-roboto font-medium text-[24px] text-[#6D6F78]'>
                            We're building a highly potent community that mindfully explores and unlocks practical opportunities for wealth accumulation and growth
                        </p>
                        <a href='https://www.instagram.com/financially_aware_bharat/' target="_blank" rel="noopener noreferrer" onClick={() => { TrackEvent('join_community_clicked') }}>
                            <div className="inline-flex items-center space-x-2 bg-[#2B4CBA] rounded-[44px] px-[44px] py-[14px] mt-[48px] w-auto">
                                <h2 className="font-roboto font-bold text-[24px] text-[#ffffff] whitespace-nowrap">Join Community</h2>
                                <img
                                    className="w-[22px] h-auto"
                                    src={images.featureArrow}
                                    alt="Feature Arrow"
                                />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='xl:hidden mt-[5%]'>
                <div className='flex justify-center mt-[10%]'>
                    <h1 className='font-roboto font-bold text-[15px] md:text-[26px] lg:text-[38px] text-[#3C3E43]'>
                        Financially Aware Bharat
                    </h1>
                </div>
                <div className='flex flex-col mx-[14px] bg-[#E2E7F8] rounded-[20px] p-[10px] mt-[10px] mb-[10px]'>
                    <div className="flex flex-col items-center justify-center flex-grow">
                        <ImageCarousel images={imagesList} altText="Descriptive alt text" timeInterval={1000} />
                        <h1 className='font-roboto font-semibold text-[12px] md:text-[22px] lg:text-[32px] text-[#3C3E43] mt-[4%] px-[20%] text-center'>We're deeply committed to improve money mindfulness</h1>
                        <a href='https://www.instagram.com/financially_aware_bharat/' target="_blank" rel="noopener noreferrer" onClick={() => { TrackEvent('join_community_clicked') }}>
                            <div className="inline-flex items-center justify-center space-x-2 bg-[#2B4CBA] rounded-[44px] px-[20px] py-[8px] mt-[15px] mb-[22px]">
                                <h2 className='font-roboto font-bold text-[14px] md:text-[21px] lg:text-[28px] text-[#ffffff]'>Join Community</h2>
                                <img
                                    className="w-[12px] md:w-[19px] lg:w-[26px] h-auto"
                                    src={images.featureArrow}
                                    alt="Feature Arrow"
                                />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ImageCarousel = ({ images, altText, timeInterval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, timeInterval);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length, timeInterval]);

    return (
        <img
            className="h-auto max-w-full rounded-[10px]"
            src={images[currentIndex]}
            alt={altText || "Image Carousel"}
        />
    );
};

export default Community;