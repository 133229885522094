// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'lucide-react';
import images from '../../utils/images';
import { Link, useNavigate } from 'react-router-dom';
import TrackEvent from '../../utils/events';
import DownloadButton from './Download';

const Navbar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null);
    const navigate = useNavigate();

    const toggleDrawer = () => setIsDrawerOpen(prev => !prev);

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setIsDrawerOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const scrollToOfferings = () => {
        navigate("/");
        setTimeout(() => {
            document.querySelector('.offerings-section')?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
        setIsDrawerOpen(false);
        TrackEvent(`navbar_offerings_clicked`)
    };

    const navLinks = [
        { name: 'About Us', href: '/about', onClick: () => { TrackEvent(`navbar_about_us_clicked`) } },
        { name: 'Our Offerings', onClick: scrollToOfferings },
        { name: 'Blog', href: '/blogs', onClick: () => { TrackEvent(`navbar_blogs_clicked`) } },
        { name: 'Contact Us', href: '/contact', onClick: () => { TrackEvent(`navbar_contact_us_clicked`) } },
    ];

    return (
        <nav className="bg-white shadow-md fixed top-0 left-0 w-full z-30">
            {/* Desktop Navbar */}
            <div className="hidden xl:flex justify-between items-center mx-[10%] my-[12px]">
                <Link to={"/"}>
                    <img
                        className="w-[175px] h-[65px]"
                        src={images.logoUrl}
                        alt="Finurja logo"
                    />
                </Link>

                <div className="flex flex-grow justify-around items-center relative">
                    {navLinks.map(({ name, href, onClick }, index) => (
                        <div key={index} className="relative">
                            <a
                                href={href || "#"}
                                onClick={onClick}
                                className="nav-link flex items-center font-roboto font-normal text-[20px] text-[#213A8F]"
                            >
                                {name}
                            </a>
                        </div>
                    ))}
                </div>

                <DownloadButton />
            </div>

            {/* Mobile Navbar */}
            <div className="xl:hidden mx-[3%] my-[12px] flex items-center relative">
                <button onClick={toggleDrawer} className="p-2">
                    <Menu className="h-6 w-6 text-gray-900" />
                </button>
                <Link to={"/"}>
                    <img
                        className="w-[98px] h-[36px]"
                        src={images.logoUrl}
                        alt="Finurja logo"
                    />
                </Link>
                <div className="absolute right-2 flex items-center mt-1">
                    <a href="https://finurjamobile.page.link/download" target="_blank" rel="noopener noreferrer">
                        <div className="bg-[#182A68] border border-[#FFFFFF] rounded-[4.4px] flex items-center justify-center w-[120px] h-[32px]">
                            <span className="text-[#FFFFFF] text-[10px] font-medium">
                                Download App
                            </span>
                        </div>
                    </a>
                </div>
            </div>

            {/* Drawer Menu */}
            <div
                ref={drawerRef}
                className={`fixed top-0 left-0 w-64 h-full bg-white shadow-md transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out xl:hidden`}
            >
                <div className="p-4">
                    <button onClick={toggleDrawer} className="text-gray-900">
                        <Menu className="h-6 w-6" />
                    </button>
                    <ul className="mt-8 space-y-4">
                        {navLinks.map(({ name, href, onClick }, index) => (
                            <li key={index}>
                                <a href={href || "#"} onClick={onClick} className="block text-gray-900">{name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
