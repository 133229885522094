// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

const BASE_URL = "https://api.finurja.com";

export const fetchBlogs = async (limit = 12, offset = 0, language = 'english') => {
    try {
        const response = await fetch(`${BASE_URL}/blogs?limit=${limit}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Language': language
            }
        });
        console.log(response)
        if (!response.ok) {
            throw new Error('Failed to fetch blogs');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching blogs:', error);
        return [];
    }
};


export const fetchBlog = async (id, language = 'english') => {
    try {
        const response = await fetch(`${BASE_URL}/blogs/${id}`, {
            method: 'GET',
            headers: {
                'Language': language
            }
        });
        console.log(response)
        if (!response.ok) {
            throw new Error('Failed to fetch blog');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching blog:', error);
        return null;
    }
};

export const saveContactForm = async (formData) => {
    try {
        const response = await fetch(`${BASE_URL}/website/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            throw new Error('Failed to send contact form');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error sending contact form:', error);
        return { success: false };
    }
};